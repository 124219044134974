<template>
  <div style="min-height: 100vh">
    <Navbar pageName="Login" />
    <div class="container my-5 card bg-light text-center">
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="usernameInput" class="form-label">Username</label>
          <input
            class="form-control"
            id="usernameInput"
            type="text"
            v-model="loginUser"
          />
          <label for="passwordInput" class="form-label">Password</label>
          <input
            class="form-control"
            id="passwordInput"
            type="password"
            v-model="loginPass"
          />
          <p v-show="validationError" class="text-danger">
            Username or password incorrect
          </p>
          <button class="btn btn-primary m-3" onclick="submit">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      loginUser: "",
      loginPass: "",
      validationError: false,
    };
  },
  methods: {
    login() {
      axios({
        method: "post",
        url: `https://api.littertracker.greenteamscanada.ca/api/login`,
        data: {
          username: this.loginUser,
          password: this.loginPass,
        },
      })
        .then((r) => {
          console.log(r);
          localStorage.setItem("token", r.data);
          window.location.replace("/admin");
        })
        .catch((e) => {
          if ((e.response.status = 401)) {
            this.validationError = true;
            setTimeout(() => {
              this.validationError = false;
            }, 15000);
          }
        });
    },
  },
};
</script>
