
import { defineComponent } from "vue";

export default defineComponent({
  name: "InterestingItem",
  props: {
    interesting: {
      type: Array,
    },
  },
  data() {
    return {
      currentInteresting: "",
    };
  },
  methods: {
    addInteresting() {
      if (this.currentInteresting) {
        this.$emit("add-interesting", this.currentInteresting);
        this.currentInteresting = "";
      }
    },
    removeInteresting(i: number) {
      this.$emit("remove-interesting", i);
    },
  },
  mounted() {},
});
