
import axios from "axios";
import { defineComponent, reactive, onMounted } from "vue";
const bootstrap = require("bootstrap/dist/js/bootstrap.min.js");

export default defineComponent({
  name: "NewEntry",
  props: {
    reg: {
      type: Boolean,
    },
  },
  data() {
    return {
      registerModal: null,
      locations: [],
      name: "",
      error: false,
    };
  },
  methods: {
    registerSession() {
      //submit location and username
      let loc = <HTMLInputElement>document.getElementById("locationInput");

      axios({
        method: "post",
        url: `https://api.littertracker.greenteamscanada.ca/api/new`,
        data: {
          user: this.name.replace(/'/g, "''").trim(),
          location_id: loc.value,
        },
      })
        .then((r) => {
          console.log(r);
          let d = new Date();
          d.setTime(d.getTime() + 86400000);
          document.cookie = `session=${
            r.data.data.uuid
          }; SameSite=Strict; expires=${d.toUTCString()};`;
          this.$emit("reg-session", r.data.data.uuid);

          this.registerModal.hide();
        })
        .catch((e) => {
          console.error(e);
          this.error = true;
        });
    },
    getLocations() {
      axios({
        method: "get",
        url: `https://api.littertracker.greenteamscanada.ca/api/locations`,
      })
        .then((r) => {
          this.locations = r.data.data;

          this.registerModal.show();
        })
        .catch((e) => {
          console.error(e);
          this.error = true;
        });
    },
  },
  mounted() {
    this.registerModal = new bootstrap.Modal(
      document.getElementById("registerModal"),
      { backdrop: false, keyboard: false }
    );

    this.getLocations();
    //if no session throw error
  },
});
