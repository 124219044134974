
import { defineComponent, setTransitionHooks } from "vue";
import Navbar from "@/components/Navbar.vue";
import axios from "axios";
const bootstrap = require("bootstrap/dist/js/bootstrap.min.js");
import { Chart, ChartItem } from "chart.js/auto";

export default defineComponent({
  name: "ResultsView",
  components: {
    Navbar,
  },
  data() {
    return {
      locations: [],
      chart: "",
      locationKey: -1,
      interesting: [],
    };
  },
  computed: {
    dataURL() {
      return `https://api.littertracker.greenteamscanada.ca/api/data/location/${this.locationKey}/download`;
    },
    itemURL() {
      return `https://api.littertracker.greenteamscanada.ca/api/data/location/${this.locationKey}/interesting/download`;
    },
    totalsURL() {
      return `https://api.littertracker.greenteamscanada.ca/api/data/location/${this.locationKey}/totals/download`;
    },
  },
  methods: {
    getLocations() {
      axios({
        method: "get",
        url: `https://api.littertracker.greenteamscanada.ca/api/locations/all`,
      })
        .then((r) => {
          this.locations = r.data.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getLocationData() {
      let n = document.getElementById("locationInput") as HTMLInputElement;
      axios({
        method: "get",
        url: `https://api.littertracker.greenteamscanada.ca/api/data/location/${n.value}/totals`,
      }).then((res) => {
        let canvas = document.getElementById("mainChart");

        document
          .getElementById("locationInput")
          .setAttribute("disabled", "disabled");
        setTimeout(() => {
          document.getElementById("locationInput").removeAttribute("disabled");
        }, 1000);
        //Clear chart if it's already been drawn
        if (this.chart) {
          this.chart.destroy();
        }

        this.chart = new Chart(canvas as ChartItem, {
          type: "bar",
          data: {
            datasets: [
              {
                label: "Litter Logged",
                data: res.data.data,
                borderWidth: 1,
              },
            ],
          },
          options: {
            parsing: {
              xAxisKey: "type",
              yAxisKey: "value",
            },
          },
        });
      });

      axios({
        method: "get",
        url: `https://api.littertracker.greenteamscanada.ca/api/data/location/${n.value}/interesting`,
      }).then((res) => {
        this.interesting = res.data.data;
      });
    },
  },
  mounted() {
    document.title = "Litter Tracker";
    this.getLocations();
  },
});
