import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainView from "../views/MainView.vue";
import AdminView from "../views/AdminView.vue";
import ResultView from "../views/ResultsView.vue";
import LoginView from "../views/LoginView.vue";
import axios from "axios";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main",
    component: MainView,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/results",
    name: "results",
    component: ResultView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = localStorage.getItem("token");
    console.log(token);
    if (!token) {
      console.log("no token");
      next("/login");
    } else {
      console.log("token - auth");
      axios({
        method: "post",
        url: `https://api.littertracker.greenteamscanada.ca/api/auth`,
        data: {
          token: token,
        },
      })
        .then((r) => {
          console.log(r);

          if (r.status == 200) {
            next();
          } else {
            next("/login");
          }
        })
        .catch((e) => {
          console.error("error: " + e);
          localStorage.removeItem("token");

          next("/login");
        });
    }
  } else {
    next();
  }
});

export default router;
