<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

#app {
  font-family: Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f7faf3;

  background-image: url("assets/tilebg.png");
  background-repeat: repeat;
  overflow-x: hidden;
}

h4 {
  font-size: 1.2em !important;
}

.badges-move, /* apply transition to moving elements */
.badges-enter-active,
.badges-leave-active {
  transition: all 0.5s ease;
}

.badges-enter-from,
.badges-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.badges-leave-active {
  position: absolute;
}
button {
  touch-action: manipulation;
}
</style>
