
import { defineComponent, setTransitionHooks } from "vue";
import axios from "axios";
import ItemCounter from "@/components/ItemCounter.vue"; // @ is an alias to /src
import InterestingItem from "@/components/InterestingItem.vue";
import Navbar from "@/components/Navbar.vue";

import NewEntry from "@/components/NewEntry.vue";
const bootstrap = require("bootstrap/dist/js/bootstrap.min.js");

export default defineComponent({
  name: "MainView",
  components: {
    ItemCounter,
    InterestingItem,
    NewEntry,
    Navbar,
  },
  data() {
    return {
      name: "Litter Tracker",
      reset: false,
      error: false,
      data: {},
      interesting: [] as Array<string>,
      lastData: {},
      lastInteresting: [] as Array<string>,
      session: "",
      reg: false,
      loading: false,
      modal: null,
      modalName: "",
      modalAmount: 0,
      categories: [
        "Cigarette Butts",
        "Food Wrappers",
        "Cups & Lids",
        "Bottles & Cans",
        "Paper & Cardboard",
        "Plastic Bags",
        "Styrofoam",
        "Glass",
        "Clothing/Shoes",
        "Metal",
        "Electronics",
        "Other",
      ],
    };
  },
  methods: {
    checkSessionCookie() {
      let cookieValues = decodeURIComponent(document.cookie).split(";");

      for (let n = 0; n < cookieValues.length; n++) {
        let x = cookieValues[n].split("=");
        if (x[0].trim() === "session") {
          return x[1];
        }
      }
      return "";
    },
    registerSession(uuid) {
      this.session = uuid;
    },
    initCount(name) {
      if (!this.data[name]) {
        this.data[name] = 0;
      }

      // this.setCookie(name);
    },
    incrementCount(name) {
      this.data[name]++;

      this.setCookie(name);
    },
    decrementCount(name) {
      if (this.data[name] > 0) {
        this.data[name]--;
        this.setCookie(name);
      }
    },
    customValueModal(name) {
      (document.getElementById("modalName") as HTMLInputElement).value = name;
      this.modalAmount = 0;
      this.modalName = name;
      this.modal.show();
    },
    customValueAdd() {
      console.log(this.modalName + " " + this.modalAmount);
      this.data[this.modalName] += this.modalAmount;
      if (this.data[this.modalName] < 0) {
        this.data[this.modalName] = 0;
      }
      this.setCookie(this.modalName);
      this.modal.hide();
    },
    setCookie(item: string, interesting: boolean) {
      let d = new Date();
      d.setTime(d.getTime() + 86400000);

      if (interesting) {
        document.cookie = `interesting_${item.replace(
          /[^\w]/g,
          ""
        )}=${item}; SameSite=Strict; Expires=${d.toUTCString()};`;
      } else {
        document.cookie = `item_${item}=${
          this.data[item]
        }; SameSite=Strict; expires=${d.toUTCString()};`;
      }
    },
    loadCookies() {
      let cookieValues = decodeURIComponent(document.cookie).split(";");

      this.interesting = [];

      for (let n = 0; n < cookieValues.length; n++) {
        let x = cookieValues[n].split("=");
        if (x[0].trim().includes("item_")) {
          this.data[x[0].split("item_")[1]] = parseInt(x[1]);
        }
        if (x[0].trim().includes("interesting_")) {
          this.interesting.push(x[1]);
        }
      }
    },
    clearData() {
      let cookieValues = decodeURIComponent(document.cookie).split(";");

      for (let n = 0; n < cookieValues.length; n++) {
        let x = cookieValues[n].split("=");

        document.cookie =
          x[0].trim() +
          "=0;SameSite=Strict; Expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        location.reload();
      }

      this.reset = false;
    },
    async uploadData() {
      console.log("uploading data");
      if (this.session) {
        this.loading = true;

        let p = [];

        if (JSON.stringify(this.data) !== JSON.stringify(this.lastData)) {
          console.log("Send items");
          p.push(
            axios({
              method: "post",
              url: `https://api.littertracker.greenteamscanada.ca/api/${this.session}/log`,
              data: this.data,
            })
          );
          this.lastData = JSON.parse(JSON.stringify(this.data));
        }

        if (
          JSON.stringify(this.interesting) !==
          JSON.stringify(this.lastInteresting)
        ) {
          console.log("Send interesting");
          p.push(
            axios({
              method: "post",
              url: `https://api.littertracker.greenteamscanada.ca/api/${this.session}/interesting`,
              data: this.interesting,
            })
          );
          this.lastInteresting = JSON.parse(JSON.stringify(this.interesting));
        }

        Promise.all(p)
          .catch((e) => {
            console.error(e);
            this.error = true;
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          });
      }
    },
    addInteresting(item: string) {
      this.interesting.push(item);
      this.setCookie(item, true);
    },
    removeInteresting(index: number) {
      document.cookie = `interesting_${this.interesting[index].replace(
        /[^\w]/g,
        ""
      )}=; SameSite=Strict; Expires=Thu, 01 Jan 1970 00:00:00 GMT`;

      let left = this.interesting.slice(0, index);
      let right = this.interesting.slice(index + 1);

      this.interesting = left.concat(right);
    },
  },
  mounted() {
    document.title = "Litter Tracker";
    this.session = this.checkSessionCookie();

    if (!this.session) {
      console.log("No session found. Generating session.");
      // this.registerSession();
      this.reg = true;
    } else {
      this.loadCookies();

      //pull session data from DB
      //compare data with cookies
      //if data does not match
      //check if cookies 0, if so go with db otherwise use cookies
    }

    this.uploadData();
    let uploadInterval = setInterval(() => {
      this.uploadData();
    }, 30000);

    // setTimeout(() => {
    //   console.log("Timer exceeded, stopping uploads");
    //   clearInterval(uploadInterval);
    // }, 21600000);

    this.modal = new bootstrap.Modal(document.getElementById("CounterModal"), {
      backdrop: true,
      keyboard: true,
    });
  },
});
