
import { defineComponent } from "vue";
const bootstrap = require("bootstrap/dist/js/bootstrap.min.js");

export default defineComponent({
  name: "ItemCounter",
  emits: ["init-value", "increment-value", "decrement-value", "custom-value"],
  props: {
    item: {
      type: String,
    },
    value: {
      type: Number,
    },
    tooltip: {
      type: String,
    },
  },
  computed: {
    tooltipID() {
      return "tt_" + this.item?.toLowerCase();
    },
  },
  methods: {
    emitInit() {
      this.$emit("init-value", this.item);
    },
    emitIncrement() {
      this.$emit("increment-value", this.item);
    },
    emitDecrement() {
      this.$emit("decrement-value", this.item);
    },
    emitCustomAdd() {
      this.$emit("custom-value", this.item);
    },
  },
  mounted() {
    this.emitInit();

    // new bootstrap.Tooltip(document.getElementById(this.tooltipID));
  },
});
