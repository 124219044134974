
import { defineComponent, setTransitionHooks } from "vue";
import Navbar from "@/components/Navbar.vue";
import axios from "axios";
const bootstrap = require("bootstrap/dist/js/bootstrap.min.js");

export default defineComponent({
  name: "AdminView",
  components: {
    Navbar,
  },
  data() {
    return {
      locations: {},
      changes: false,
      newevent_name: "",
      newevent_date: "",
      newevent_active: false,
    };
  },
  computed: {
    newevent_valid() {
      return this.newevent_name != "" && this.newevent_date != ""
        ? true
        : false;
    },
  },
  methods: {
    getLocations() {
      axios({
        method: "get",
        url: `https://api.littertracker.greenteamscanada.ca/api/locations/all`,
      })
        .then((r) => {
          this.locations = r.data.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    updateEvents(event) {
      console.log(event.srcElement.checked ? 1 : 0);

      axios({
        method: "PUT",
        url: `https://api.littertracker.greenteamscanada.ca/api/location/${
          event.srcElement.id.split("eventactive_")[1]
        }/update`,
        data: {
          active: event.srcElement.checked ? 1 : 0,
        },
      })
        .then((r) => {})
        .catch((e) => {
          console.error(e);
        });
    },
    addEvent() {
      let data = {
        location_name: this.newevent_name.replace(/'/g, "''").trim(),
        event_date: this.newevent_date,
        active: this.newevent_active,
      };

      axios({
        method: "POST",
        url: `https://api.littertracker.greenteamscanada.ca/api/locations`,
        data: data,
      })
        .then((r) => {
          location.reload();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    logout() {
      localStorage.removeItem("token");
      window.location.replace("/login");
    },
  },
  mounted() {
    document.title = "Litter Tracker";

    this.getLocations();
  },
});
